import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Button, DatePicker, Form, Input, Popover, Select } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { ContractInfoSlaModal, ContractTerminateModal, ContractPublishModal } from "components/contracts";
import { useContractors } from "helpers/useContractors";
import editBlue from "data/icons/edit-blue.svg";
import arrowUp from "data/icons/arrow-up.svg";
import dotsMenu from "data/icons/dots-menu.svg";
import closeRed from "data/icons/close-red.svg";
import eyeGray from "data/icons/eye-gray.svg";
import api from "services/api.service";

import "./ContractInfoForm.scss";

interface IProps {
  contractEdit: boolean;
  setContractEdit: (value: boolean) => void;
  onSubmitEquipments: (contractId: number) => Promise<IEquipmentSource[]>;
}

const ContractInfoForm = ({ contractEdit, setContractEdit, onSubmitEquipments }: IProps) => {
  const dispatch = useAppDispatch();
  const [ form ] = Form.useForm();

  const { currentContract } = useAppSelector(getContractsSelector);
  const { contractors, getContractors } = useContractors();

  const [ isSlaModalOpen, setSlaModalOpen ] = useState<boolean>(false);
  const [ slaIdNew, setSlaIdNew ] = useState<number>();
  const [ isLoading, setIsLoading ] = useState(false);

  const [ isTerminateModal, setIsTerminateModal ] = useState(false);
  const [ isPublishModal,setIsPublishModal ] = useState(false);

  const initialValues = currentContract ? {
    identifier: currentContract.identifier,
    contractor: currentContract?.customer ? {
      value: currentContract?.customer?.id, label: currentContract?.customer?.name } : null,
    date_of_signing: currentContract.date_of_signing ? dayjs(currentContract.date_of_signing) : null,
    date_begin: currentContract.date_begin ? dayjs(currentContract.date_begin) : null,
    date_end: currentContract.date_end ? dayjs(currentContract.date_end) : null,
    alias: currentContract.meta.alias,
    information: currentContract.meta.information,
  } : {};

  const handleCompleteForm = async (values: any) => {
    if (currentContract) {
      setIsLoading(true);

      const updatedContract = await api.updContract(currentContract.id, {
        ...values,
        date_of_signing: values.date_of_signing ? dayjs(values.date_of_signing).format("YYYY-MM-DD") : null,
        date_begin: values.date_begin ? dayjs(values.date_begin).format("YYYY-MM-DD") : null,
        date_end: values.date_end ? dayjs(values.date_end).format("YYYY-MM-DD") : null,
      });

      const updatedAlias = await api.setContractAlias(values.alias, currentContract.id);
      const updatedEquipments = await onSubmitEquipments(currentContract.id);

      dispatch(getContract(currentContract.id));
      dispatch(getContractEquipments(+currentContract.id));

      setContractEdit(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContractors("");
  }, []);

  useEffect(() => {
    if (currentContract) {
      form.setFieldsValue(initialValues);
    }
  }, [ currentContract ]);

  if (!contractEdit && currentContract) {
    return (
      <div className="contract-info-view-wrapper">
        <ContractTerminateModal
          id={ currentContract.id }
          isTerminateModal={ isTerminateModal }
          setIsTerminateModal={ setIsTerminateModal }
        />

        <ContractPublishModal
          id={ currentContract.id }
          name={ currentContract?.customer?.name }
          isPublishModal={ isPublishModal }
          setIsPublishModal={ setIsPublishModal }
        />

        <div className="contract-info-view">
          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Contract number:
            </div>
            {currentContract.identifier}
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Customer:
              </div>
              {currentContract?.customer?.name}
            </div>

            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Contract date:
              </div>
              {currentContract.date_of_signing}
            </div>
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Service provider:
              </div>
              {currentContract?.service_provider?.name}
            </div>

            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                End date:
              </div>
              {currentContract.date_end}
            </div>
          </div>

          <div className="contract-info-view-group">
            <div className="contract-info-view-item">
              <div className="contract-info-view-item-title">
                Start date:
              </div>
              {currentContract.date_begin}
            </div>
          </div>

          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Alias:
            </div>
            {currentContract.meta.alias}
          </div>

          <div className="contract-info-view-item">
            <div className="contract-info-view-item-title">
              Information:
            </div>
            {currentContract.meta.information}
          </div>

          <div className="contract-info-view-item">
            <Button
              className="sla-button"
              type="link"
              onClick={ () => setSlaModalOpen(true) }
            >
              Service Level Agreement
              <img src={ arrowUp } alt=""/>
            </Button>
          </div>

          {isSlaModalOpen && (
            <ContractInfoSlaModal
              contractId={ currentContract.id }
              slaId={ slaIdNew || currentContract.sla_pk }
              isSlaModalOpen={ isSlaModalOpen }
              setSlaModalOpen={ setSlaModalOpen }
              setSlaIdNew={ setSlaIdNew }
            />
          )}
        </div>

        {currentContract.granted_edit ? (
          <div className="contract-info-view-buttons">
            <Button
              className="contract-info-view-button"
              onClick={ () => setContractEdit(true) }
            >
              Edit contract
              <img src={ editBlue } alt=""/>
            </Button>

            {currentContract.status === "draft" && (
              <Button
                type="primary"
                className="contract-info-view-button"
                onClick={ () => setIsPublishModal(true) }
              >
                Publish contract
              </Button>
            )}

            <Popover
              placement="bottomRight"
              content={ (
                <div onClick={ () => setIsTerminateModal(true) }>
                  <div className="terminate">
                    Terminate

                    <img src={ closeRed } alt=""/>
                  </div>
                </div>
              ) }
              trigger="click"
            >
              <Button
                className="contract-info-view-buttons-more"
                type="text"
                shape="circle"
                icon={ <img src={ dotsMenu } alt="" /> }
              />
            </Popover>
          </div>
        ) : (
          <div className="view-only">
            <img src={ eyeGray } alt=""/>

            View only
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Form
        name="contract-info-form"
        className="contract-info-form"
        layout="horizontal"
        autoComplete="off"
        form={ form }
        onFinish={ handleCompleteForm }
        initialValues={ initialValues }
      >
        <div className="contract-info-form-wrapper">
          <div className="contract-info-form-form">
            <Form.Item
              label="Contract number"
              name="identifier"
              rules={ [ { required: true, message: "Please input contract number!" } ] }
              style={ { marginBottom: "10px" } }
            >
              <Input/>
            </Form.Item>

            <div style={ { display: "flex", width: "100%", gap: "20px" } }>
              <Form.Item
                label="Contractor"
                name="contractor"
                rules={ [ { required: true, message: "Please select contractor!" } ] }
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <Select
                  options={ contractors }
                  showSearch
                  onSearch={ (value) => getContractors(value) }
                  filterOption={ false }
                  placeholder="Choose contractor"
                  notFoundContent={ null }
                />
              </Form.Item>

              <Form.Item
                label="Contract date"
                name="date_of_signing"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>
            </div>

            <div style={ { display: "flex", width: "100%", gap: "20px" } }>
              <Form.Item
                label="Start Date"
                name="date_begin"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>

              <Form.Item
                label="End Date"
                name="date_end"
                style={ { marginBottom: "10px",  width: "100%" } }
              >
                <DatePicker style={ { width: "100%" } } />
              </Form.Item>
            </div>

            <Form.Item
              label="Alias"
              name="alias"
              style={ { marginBottom: "10px" } }
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Information"
              name="information"
            >
              <Input/>
            </Form.Item>
          </div>

          <div className="contract-info-form-buttons">
            <Button
              size="large"
              onClick={ () => setContractEdit(false) }
            >
              Cancel
            </Button>

            <Button
              disabled={ isLoading }
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ContractInfoForm;
