import { useEffect, useState } from "react";

import { Button, Input } from "antd";
// import type { ColorPickerProps, GetProp } from "antd";

import deleteIcon from "data/icons/trash.svg";
import plusBlue from "data/icons/plus-blue.svg";
import editIcon from "data/icons/edit.svg";
import api from "services/api.service";
import { ISlaCi } from "store/contracts/contracts.types";
// import { invertColor } from "helpers/useColorsPicker";

// type TColor = GetProp<ColorPickerProps, "value"> | undefined;

const initialSlaCi = {
  id: 0,
  name: "",
  marker: "",
  marker_bg_color: "",
  marker_text_color: "",
};

const SupportType = () => {
  const [ slaCi, setSlaCi ] = useState<ISlaCi[]>([]);
  const [ addMode, setAddMode ] = useState<boolean>(false);
  const [ newSla, setNewSla ] = useState(initialSlaCi);
  const [ editSupportType, setEditSupportType ] = useState<Partial<ISlaCi>>(initialSlaCi);
  // const [ color, setColor ] = useState<TColor>("#E8F0F2");

  const getSlaCi = async () => {
    const response = await api.getSlaCi();

    if (response.data.results) {
      setSlaCi(response.data.results);
    }
  };

  const onNewSlaNameChange = (e: { target: { value: any; }; }) => {
    setNewSla(prev => ({ ...prev, name: e.target.value }));
  }

  const onNewSlaMarkerChange = (e: { target: { value: any; }; }) => {
    setNewSla(prev => ({ ...prev, marker: e.target.value }));
  }
  const onAddSupportType = async () => {
    if (newSla.name && newSla.marker) {
      setNewSla(initialSlaCi);
      setAddMode(false);

      const response = await api.createSlaCi(
        newSla.name,
        newSla.marker,
        // @ts-ignore
        // color?.toHexString?.() || color, color?.toHexString?.() ? invertColor(color.toHexString()) : "#3E9CB0",
      );

      if (response.data) {
        getSlaCi();
      }
    }
  };

  const onSaveSupportType = async () => {
    if (editSupportType.id && editSupportType.name && editSupportType.marker) {
      setEditSupportType(initialSlaCi);

      const response = await api.updateSlaCi(
        editSupportType.id,
        editSupportType.name,
        editSupportType.marker,
        // @ts-ignore
        // color?.toHexString?.() || color, color?.toHexString?.() ? invertColor(color.toHexString()) : "#3E9CB0",
      );

      if (response.data) {
        getSlaCi();
      }
    }
  };

  const onEditSlaNameChange = (e: { target: { value: any; }; }) => {
    setEditSupportType(prev => ({ ...prev, name: e.target.value }));
  }

  const onEditSlaMarkerChange = (e: { target: { value: any; }; }) => {
    setEditSupportType(prev => ({ ...prev, marker: e.target.value }));
  }

  const onDeleteSupportType = (id: number) => {
    console.log(id);
  };

  useEffect(() => {
    getSlaCi();
  }, []);

  return (
    <div className="section">
      <div className="section-title" style={ { marginBottom: "30px" } }>
        Support type
      </div>

      {/*<div className="section-text">*/}
      {/*  Create your own support type according to your needs*/}
      {/*</div>*/}

      <div className="section-list">
        {slaCi.map((supportType, index) => editSupportType?.id === supportType.id ? (
          <div
            className="section-edit"
            key={ supportType.id }
            style={ index === 0 ? {
              border: "none",
              marginTop: "-1px",
            } : undefined }
          >
            <Input
              value={ editSupportType.marker }
              onChange={ onEditSlaMarkerChange }
              placeholder="Abbreviated"
              style={ { width: "100px", minWidth: 100 } }
            />

            {/*<ColorPicker*/}
            {/*  value={ color }*/}
            {/*  onChange={ setColor }*/}
            {/*/>*/}

            <Input
              value={ editSupportType.name }
              onChange={ onEditSlaNameChange }
              placeholder="Full title"
            />

            <Button
              type="primary"
              onClick={ onSaveSupportType }
            >
              Save
            </Button>
          </div>
        ) : (
          <div
            className="section-list-item"
            key={ supportType.id }
          >
            <div
              className="section-list-item-badge"
              style={ {
                background: supportType.marker_bg_color,
                color: supportType.marker_text_color,
              } }
            >
              {supportType.marker}
            </div>

            <div className="section-list-item-title">
              {supportType.name}
            </div>

            {!supportType.is_default && (
              <>
                <Button
                  type="text"
                  icon={ <img src={ editIcon } alt="" /> }
                  onClick={ () => setEditSupportType(supportType) }
                />

                <Button
                  type="text"
                  className="section-list-item-button"
                  icon={ <img src={ deleteIcon } alt=""/> }
                  onClick={ () => onDeleteSupportType(supportType.id) }
                >
                  Delete
                </Button>
              </>
            )}
            {/*{supportType.onDelete ? (*/}
            {/*  <Button*/}
            {/*    type="text"*/}
            {/*    className="section-list-item-button"*/}
            {/*    icon={ <img src={ deleteIcon } alt=""/> }*/}
            {/*    onClick={ () => onDeleteSupportType(supportType.id) }*/}
            {/*  >*/}
            {/*    Delete*/}
            {/*  </Button>*/}
            {/*) : (*/}
            {/*  <div className="section-list-item-info">*/}
            {/*    Basic*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        ))}
      </div>

      {addMode && (
        <div className="section-add">
          <Input
            value={ newSla.marker }
            onChange={ onNewSlaMarkerChange }
            placeholder="Abbreviated"
            style={ { width: "100px", minWidth: 104 } }
          />

          {/*<ColorPicker*/}
          {/*  value={ color }*/}
          {/*  onChange={ setColor }*/}
          {/*/>*/}

          <Input
            value={ newSla.name }
            onChange={ onNewSlaNameChange }
            placeholder="Full title"
          />

          <Button
            type="primary"
            onClick={ onAddSupportType }
          >
            Create
          </Button>
        </div>
      )}

      <Button
        type="dashed"
        className="section-list-button"
        icon={ <img src={ plusBlue } alt=""/> }
        onClick={ () => {
          setAddMode(true)
        } }
      >
        Add Support type
      </Button>
    </div>
  );
};

export default SupportType;
